import { WIDGET_URL } from 'config';
import { IConfig, IFooterFormState, IFormState } from 'interfaces';

export const ORDER_DEFAULT_TITLE = '';

export const OPTTY_LOGO_URL = `${WIDGET_URL}/images/optty/black/optty.svg`;

export const OPTTY_WHITE_LOGO_URL = `${WIDGET_URL}/images/optty/white/optty.svg`;

export const OPTTY_WHITE_TM_LOGO_URL = `${WIDGET_URL}/images/optty/white/optty-tm.svg`;

export const OPTTY_SUB_LOGO_URL = `${WIDGET_URL}/images/optty/black/optty-sub.svg`;

export const OPTTY_SUB_TM_LOGO_URL = `${WIDGET_URL}/images/optty/black/optty-tm-sub.svg`;

export const OPTTY_WHITE_SUB_TM_LOGO_URL = 'https://optty.atlassian.net/secure/viewavatar?size=xxxlarge@2x&avatarId=10557&avatarType=project';

export const USER_ROLES = [
  {
    value: 'admin',
    title: 'Admin',
    subTitle: 'Full Access',
  },
  {
    value: 'management',
    title: 'Management',
    subTitle: 'Full Access(Excluding User Management)',
  },
  {
    value: 'reporting',
    title: 'Reporting',
    subTitle: 'Viewer Access(Reports & Analytics only)',
  },
];

export enum ColorConfig {
  primaryColor = 'Primary Color',
  secondaryColor = 'Secondary Color',
  buttonColor = 'Button Color',
  textColor = 'Text Color',
}

export const maxCharacters = {
  footerTexts: {
    primaryText: 32,
    secondaryText: 160,
    extraText: 0,
  },
  productListingTexts: {
    primaryText: 32,
    tooltipText: 32,
    extraText: 0,
  },
  productDetailNoHoverTexts: {
    primaryText: 20,
    secondaryText: 20,
    extraText: 0,
  },
  productDetailHoverTexts: {
    primaryText: 32,
    headingText: 32,
    secondaryText: 150,
    extraText: 45,
  },
  cartTexts: {
    primaryText: 15,
    secondaryText: 60,
    extraText: 0,
  },
  opttyBoxTexts: {
    primaryText: 32,
    secondaryText: 500,
    extraText: 50,
  },
  checkoutTexts: {
    primaryText: 45,
    secondaryText: 150,
    extraText: 0,
  },
};

export const DefaultState = {
  primaryText: '',
  secondaryText: '',
  extraText: '',
  primaryTextLengthExceeded: false,
  secondaryTextLengthExceeded: false,
  extraTextLengthExceeded: false,
};

export const DefaultWidgetVisibilityState = {
  opttyVisibility: true,
  footer: true,
  productListing: true,
  productDetails: true,
  cartCheckout: true,
};

export enum ThemesEnum {
  THEME1 = 'theme1',
  THEME2 = 'theme2'
}

export enum FieldTypes {
  FONT = 'font',
  COLOR = 'color',
  TEXT = 'text',
  SELECT = 'select',
  FILE = 'file',
  TEXTBOX = 'textbox',
}

export enum WidgetsEnum {
  FOOTER = 'footer',
  PRODUCT_LISTING = 'productListing',
  PRODUCT_DETAILS = 'productDetails',
  CART = 'cart',
  CHECKOUT = 'checkout',
}

export enum ButtonText {
  global = 'Place Order',
  custom = 'Place Order',
}

export enum ButtonPosition {
  global = 'right',
  custom = 'right',
}

export enum ButtonSize {
  global = 'small',
  custom = 'small',
}

export enum ButtonBgColor {
  global = '#000000',
  custom = '#000000',
}

export enum ButtonTextColor {
  global = '#ffffff',
  custom = '#ffffff',
}

export const defaultFooterFormState: IFooterFormState = {
  active: ThemesEnum.THEME1,
  visible: true,
  themes: [{
    key: ThemesEnum.THEME1,
    properties: {},
  }],
};

export const defaultFormState: IFormState = {
  active: ThemesEnum.THEME1,
  visible: true,
  theme: {
    key: ThemesEnum.THEME1,
    properties: {},
  },
};

export const defaultThemeConfig: IConfig = {
  active: ThemesEnum.THEME1,
  visible: true,
  themes: [{
      key: ThemesEnum.THEME1,
      properties: {},
    }],
};

export const editorConfig = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount table textcolor',
  ],
  toolbar: 'undo redo | formatselect | fontselect | fontsizeselect | '
    + 'bold italic forecolor backcolor | alignleft aligncenter '
    + 'alignright alignjustify | table image | bullist numlist outdent indent | '
    + 'removeformat',
};
