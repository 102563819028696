import { IOnboardingForm } from 'components/Onboarding/OnboardingForm';
import axios, { AxiosResponse } from 'axios';
import { IPaymentMethod } from 'components/PaymentMethods/types';
import storageService from './storage.service';
import { IUser } from './auth.service';
import { IMerchant, IUserDetails } from '../components/Onboarding/OnboardingUserTable';

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_OPTTY_ADMIN_API_URL,
  headers: {
    Authorization: `Bearer ${storageService.getJwtCookie()}`,
    'Content-Type': 'application/json',
  },
});

export default {
  async updatePaymentMethod(paymentMethod: IPaymentMethod): Promise<any> {
    console.log(`paymentMethod in updatePaymentMethod: ${JSON.stringify(paymentMethod)}`);
    //

    return adminApi.post('/paymentMethods', paymentMethod)
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getMerchants api service  ${err}`);
          throw err;
        });
  },

  async getMerchants(): Promise<IMerchant[]> {
    return adminApi.get('/getMerchants')
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getMerchants api service  ${err}`);
          throw err;
        });
  },

  async getAuditLog(): Promise<any> {
    return adminApi.get('/auditLog')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getAuditLog api service  ${err}`);
          throw err;
        });
  },

  async getPaymentMethods(): Promise<any> {
    return adminApi.get('/paymentMethods')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getPaymentMethods api service  ${err}`);
          throw err;
        });
  },

  // onboard new merchant
  async onboardMerchant(form: IOnboardingForm): Promise<any> {
    return adminApi.post('/onboardMerchant', form)
        .then(async (res) => {
          console.log(`res in onboardMerchant: ${JSON.stringify(res)}`);
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in onboardMerchant api service  ${err}`);
          throw err;
        });
  },

  // onboard new merchant
  async onboardUser(form: IUserDetails): Promise<any> {
    return adminApi.post('/onboardUser', form)
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in onboardUser api service  ${err}`);
          throw err;
        });
  },

  // check authenticated
  async checkAuthenticated(jwt: string): Promise<boolean> {
    return adminApi.get('/auth', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error('Authentication failed');
      })
      .then((data: string) => {
        console.log(data);
        const isAuthenticated = Object.keys(data).length !== 0;
        console.log(isAuthenticated);
        return isAuthenticated;
      })
      .catch((error) => {
        console.error('Error occurred:', error);
        return false;
      });
  },

  // login
  async login(jwt: string): Promise<IUser | undefined> {
    console.log(`adminApi: ${adminApi}`);
    console.log(process.env.REACT_APP_OPTTY_ADMIN_API_URL);
    return adminApi.get('/auth/login', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        console.log(`res in login: ${res}`);
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error(`Authentication failed: ${res.statusText}`);
      })
      .catch((error) => {
        console.error('Error occurred unauthed:', error);
        return undefined;
      });
  },
};
